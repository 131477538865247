<template>
  <div class="table-container">
    <div
      v-if="mode == 'editor'"
      class="editor-toolbar"
      :class="isEditing ? 'editing' : ''"
    >
      <slot name="toolbar"></slot>
    </div>
    <table
      class="table table-condensed table-bordered table-hover dataTable table-striped  table-responsive"
    >
      <thead>
        <tr>
          <th>{{ $t("location") }}</th>
          <th>{{ $t("name") }}</th>
          <th>{{ $t("connection") }}</th>
        </tr>
      </thead>
      <tbody v-if="deviceList && deviceList.length">
        <tr>
          <td>{{ itemLocation(deviceList[0].connector) }}</td>
          <td>{{ itemName(deviceList[0].connector) }}</td>
          <td class="">
            <span
              v-if="
                deviceList[0].connector && deviceList[0].connector.is_connected
              "
              class="text-success"
            >
              <i class="fa fa-check"></i> {{ $tc("connected") }}
            </span>
            <span v-else class="text-danger">
              <i class="fa fa-exclamation-triangle"></i>
              {{ $tc("disconnected") }}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="9">{{ $t("equipment_not_selected") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EquipmentTableBasicInfo",
  props: {
    equipment: Object,
    panel: {
      type: Object,
      default: () => null,
      required: false
    },
    title: {
      type: String,
      default: "history",
      required: false
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  methods: {
    itemName: function(item) {
      return ((item?.name || "").replace(/\_/g, " ") || "").toUpperCase();
    },
    itemLocation: function(item) {
      return (item?.location_name || "").toUpperCase();
    }
  },
  computed: {
    ...mapGetters("dashboard", {
      deviceList: "deviceListFromEquipment"
    })
  },
  mounted() {
    this.$store.dispatch("dashboard/fetchResourcesFrom", {
      resource: "device",
      connectorId: this.equipment?.id,
      forceUpdate: true
    });
  }
};
</script>

<style scoped>
.table {
  margin-bottom: 0;
}
.table-container {
  overflow: auto;
}
.table-container th,
table-container td {
  vertical-align: top;
}
.table-container td {
  vertical-align: top;
  white-space: nowrap;
}
.nowrap {
  white-space: nowrap;
}
tbody {
  background-color: whitesmoke;
}

.editor-toolbar {
  position: absolute;
  right: 0px;
  width: 100%;
  border-top: 3px solid #3c8dbc;
}

.editing {
  border-top-color: #f39c12;
}

.editor-toolbar > div {
  background-color: white;
}
</style>
